<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Graphs</title>
    <path
      d="M71.6549 13.545L58.452 0.345C58.2269 0.12 57.9118 0 57.5968 0H27.59C25.6096 0 23.9892 1.62 23.9892 3.6V10.86C10.1111 11.505 -0.63128 23.295 0.0288681 37.185C0.644006 50.16 11.0263 60.54 23.9892 61.14V68.4C23.9892 70.38 25.6096 72 27.59 72H68.3992C70.3796 72 72 70.38 72 68.4V14.4C72 14.085 71.88 13.77 71.6549 13.545ZM58.797 4.095L67.9191 13.2H60.0123C59.3522 13.2 58.812 12.66 58.812 12V4.095H58.797ZM2.3844 36C2.3994 23.88 11.8815 13.89 23.9892 13.23V35.505L8.26571 51.225C4.48487 47.055 2.3844 41.625 2.3844 36ZM9.96109 52.92L25.6846 37.2H47.9646C47.3195 49.755 36.6371 59.415 24.0793 58.77C18.8431 58.5 13.847 56.445 9.96109 52.92ZM69.5995 68.4C69.5995 69.06 69.0593 69.6 68.3992 69.6H27.59C26.9299 69.6 26.3898 69.06 26.3898 68.4V61.14C39.8178 60.495 50.3652 49.44 50.3952 36C50.3952 35.34 49.855 34.8 49.1949 34.8H26.3898V3.6C26.3898 2.94 26.9299 2.4 27.59 2.4H56.3965V12C56.3965 13.98 58.0169 15.6 59.9973 15.6H69.5995V68.4Z"
      fill="currentColor"
    />
    <path
      d="M29.9906 6C29.3304 6 28.7903 6.54 28.7903 7.2V31.2C28.7903 31.86 29.3304 32.4 29.9906 32.4H53.996C54.6561 32.4 55.1962 31.86 55.1962 31.2C55.1812 17.295 43.9137 6.015 29.9906 6ZM31.1909 30V8.43C42.8335 9.06 52.1356 18.36 52.7657 30H31.1909Z"
      fill="currentColor"
    />
    <path
      d="M56.3965 45.6C50.4252 45.6 45.5941 50.43 45.5941 56.4C45.5941 62.37 50.4252 67.2 56.3965 67.2C62.3678 67.2 67.1989 62.37 67.1989 56.4C67.1989 50.445 62.3678 45.615 56.3965 45.6ZM56.3965 64.8C51.7605 64.8 47.9946 61.035 47.9946 56.4C47.9946 51.765 51.7605 48 56.3965 48C61.0325 48 64.7984 51.765 64.7984 56.4C64.7984 61.035 61.0325 64.8 56.3965 64.8Z"
      fill="currentColor"
    />
    <path
      d="M53.996 58.305L51.2504 55.56L49.555 57.255L53.1558 60.855C53.6209 61.32 54.3861 61.32 54.8512 60.855L62.0528 53.655L60.3574 51.96L53.996 58.305Z"
      fill="currentColor"
    />
    <path
      d="M57.5968 20.4H67.1989V22.8H57.5968V20.4Z"
      fill="currentColor"
    />
    <path
      d="M57.5968 27.6H67.1989V30H57.5968V27.6Z"
      fill="currentColor"
    />
    <path
      d="M57.5968 34.8H67.1989V37.2H57.5968V34.8Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-graphs',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
